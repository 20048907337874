<template>
  <section
    v-if="success"
    class="
      success
      fixed
      top-0
      left-0
      z-[9999]
      w-full
      h-screen
      bg-[#01010188]
      flex
      items-center
      justify-center
    "
  >
    <div class="relative min-w-[300px] max-w-[350px] p-4 bg-form rounded-[8px]">
      <div class="flex items-center justify-end">
        <div
          @click="$router.push('/')"
          class="relative cursor-pointer w-[16px] h-[16px]"
        >
          <span
            class="
              w-full
              absolute
              top-[8px]
              left-0
              block
              h-[2px]
              bg-white
              rotate-45
            "
          ></span>
          <span
            class="
              w-full
              absolute
              top-[8px]
              left-0
              block
              h-[2px]
              bg-white
              -rotate-45
            "
          ></span>
        </div>
      </div>
      <div class="checkMark flex items-center justify-center w-full mt-12">
        <span
          class="
            inline-flex
            items-center
            justify-center
            rounded-full
            bg-green
            w-[80px]
            h-[80px]
          "
        >
          <img src="@/assets/svg/done.svg" class="w-[50%]" alt="" />
        </span>
      </div>
      <div class="w-full mt-12">
        <p
          class="text-2xl font-semibold text-center font-aleo"
          style="letter-spacing: 0.02em"
        >
          Details Received!
        </p>
        <p class="text-center text-xs mt-2">
          Your entry is acknowledged. <br />
          Thank you for choosing Qoray.
        </p>
      </div>
    </div>
  </section>
  <section v-else class="w-full">
    <div class="ovl h-[90vh] bg-black bg-opacity-50 lg:backdrop-blur-sm">
      <div
        class="
          max-w-[68.3rem]
          mx-auto
          w-[95%]
          flex
          items-center
          justify-center
          h-full
          p-4
          overflow-y-auto
          max-h-[80vh]
          relative
        "
      >
        <form
          @submit.prevent="submitForn"
          class="rounded-2xl p-6 bg-black md:w-[400px] w-full relative"
        >
          <!-- <img src="@/assets/images/logo.png" class="mx-auto mb-3" alt="" /> -->
          <p class="text-sm mb-3">
            Please fill out this form to join the charge station subscriber list
            to charge your vehicle
          </p>
          <p
            class="text-xs mb-2 underline cursor-pointer"
            @click="showWhy = !showWhy"
          >
            {{ showWhy ? "Close" : "Why should I join?" }}
          </p>
          <p class="text-xs mb-2" v-if="showWhy">
            Qoray subscribers will have unlimited access to a network of charging
            points across the countryas we roll out
          </p>
          <div class="form-group w-full mb-3">
            <input
              v-model="form.name"
              class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
              type="text"
              placeholder="Full Name*"
            />
            <p class="mt-1 text-red text-xs">{{ errs.name }}</p>
          </div>
          <div class="form-group w-full mb-3">
            <input
              v-model="form.email"
              class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
              type="email"
              placeholder="Email Address*"
            />
            <p class="mt-1 text-red text-xs">{{ errs.email }}</p>
          </div>
          <div class="form-group w-full mb-3">
            <input
              v-model="form.phone"
              class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
              type="number"
              placeholder="Phone Number"
            />
            <p class="mt-1 text-red text-xs">{{ errs.phone }}</p>
          </div>
          <div class="form-group w-full mb-3">
            <select
              v-model="form.vehicle_type"
              class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
            >
              <option value="" disabled selected>Vehicle Type*</option>
              <option
                v-for="(product, index) in vehicle_types"
                :key="index"
                :value="product"
              >
                {{ product }}
              </option>
            </select>
            <p class="mt-1 text-red text-xs">{{ errs.vehicle_type }}</p>
          </div>
          <div class="form-group w-full mb-3">
            <input
              v-model="form.vehicle_name"
              class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
              type="text"
              placeholder="Vehicle Name"
            />
          </div>
          <button class="red-btn w-[200px] mt-3">Submit</button>
        </form>
        <div
          v-if="loading"
          class="
            loader
            absolute
            top-0
            left-0
            w-full
            h-full
            flex
            items-center
            justify-center
            bg-[#01010188]
            z-50
          "
        >
          <span
            class="
              inline-flex
              items-center
              w-[30px]
              h-[30px]
              bg-red
              rounded-full
              rott
            "
          >
            <span class="bg-black w-[15px] h-[2px]"></span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      my_vehicle_types: ["Bus", "Bike", "Sedan", "SUV", "Mini-bus", "Keke"],
      success: false,
      loading: false,
      showWhy: false,
      form: {
        name: "",
        email: "",
        phone: "",
        vehicle_type: "",
        vehicle_name: "",
        timeStamp: new Date(),
      },
      errs: {
        name: "",
        email: "",
        phone: "",
        vehicle_type: "",
        vehicle_name: "",
      },
    };
  },
  computed: {
    vehicle_types() {
      return this.my_vehicle_types.sort();
    },
  },
  methods: {
    validateForm() {
      this.errs = {
        name: "",
        email: "",
        phone: "",
        vehicle_type: "",
        vehicle_name: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      this.form.email = this.form.email.trim();
      if (this.form.name.length < 1) {
        this.errs.name = "Name is required";
        return false;
      }
      if (this.form.name.length < 3) {
        this.errs.name = "Name is too short";
        return false;
      }
      if (this.form.email.length < 1) {
        this.errs.email = "Email is required";
        return false;
      }
      if (!this.form.email.match(rege)) {
        this.errs.email = "Invalid email";
        return false;
      }
      // if (this.form.phone.length < 1) {
      //   this.errs.phone = "Phone number is required";
      //   return false;
      // }
      // if (this.form.phone.length < 11) {
      //   this.errs.phone = "Phone number is too short";
      //   return false;
      // }
      if (this.form.vehicle_type.length < 1) {
        this.errs.vehicle_type = "Please select vehicle type";
        return false;
      } else {
        var valid = false;
        this.vehicle_types.forEach((product) => {
          if (this.form.vehicle_type === product) {
            valid = true;
          }
        });
        if (!valid) {
          this.errs.vehicle_type = "Invalid vehicle type selected";
          return false;
        }
      }
      return true;
    },
    submitForn() {
      // console.log(process.env.VUE_APP_API_URL);
      if (this.validateForm()) {
        // console.log("submitting");
        this.loading = true;
        fetch(process.env.VUE_APP_API_URL + "wait-form/data.json", {
          method: "POST",
          body: JSON.stringify(this.form),
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            this.loading = false;
            this.success = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background-image: url("@/assets/images/banner.png");
  background-size: cover;
  @media (min-width: 786px) {
    /* background-image: url("@/assets/images/misvis.png"); */
    background-position: right center;
  }
  background-position: 66% center;
}
</style>
<style lang="scss">
form {
  * {
    background-color: transparent;
  }
}
</style>