<template>
  <div class="w-full max-h-screen min-h-[100vh] bg-white">
    <div
      class="md:min-h-[80vh] min-h-screen md:max-h-[80vh] overflow-hidden bgg"
    >
      <div class="w-[90%] max-w-xl mx-auto pt-8 flex justify-start">
        <img
          src="@/assets/images/qorayLogo.png"
          class="lg:w-2/12 w-[150px]"
          alt=""
        />
      </div>
      <div class="w-[90%] max-w-xl mx-auto xl:mt-[12vh] lg:mt-[9vh] mt-[13vh]">
        <h1
          class="text-center w-full text-[#4F4F4F] font-inter sm:text-5xl text-4xl font-medium"
        >
          Power moves <br class="sm:hidden block" />
          <span class="text-[#0A2C4D] font-semibold">Differently</span>
        </h1>
      </div>
      <sh-links class="md:hidden flex" />
      <!-- <img src="@/assets/images/sh-bg.png" class="w-full" alt="" /> -->
    </div>
    <div class="min-h-[20vh] max-h-[20vh] w-full hidden md:block">
      <sh-links class="md:flex hidden" />
    </div>
  </div>
</template>

<script>
import ShLinks from "@/components/ShLinks.vue";
export default {
  components: { ShLinks },
};
</script>

<style lang="scss" scoped>
body {
  @apply bg-white;
}
.bgg {
  background-image: url("@/assets/images/sh-bg.png");
  background-size: cover;
  background-position: bottom center;
}
</style>