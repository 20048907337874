<template>
  <div class="boddd overflow-hidden relative">
    <navbar v-if="showNav" />
    <router-view />
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import gsap from "gsap";
export default {
  data() {
    return {
      noNavRouteNames: ["sterling-human"],
    };
  },
  components: { Navbar },
  computed: {
    showNav() {
      return (
        this.noNavRouteNames.findIndex((nam) => nam === this.$route.name) === -1
      );
    },
  },
  mounted() {
    // window.location.href = "https://qoraymobility.com/";
    gsap.set(".conForm", { x: "101%" });
    var cbtns = Array.from(document.getElementsByClassName("conBtn"));
    cbtns.forEach((btn) => {
      btn.addEventListener("click", this.openCon);
    });
    if (
      this.$route.query.contactform &&
      this.$route.query.contactform === "open"
    ) {
      window.addEventListener("load", () => {
        this.openCon();
      });
    }
  },
  methods: {
    openCon() {
      gsap.to(".conForm", { x: "0%", duration: 0.5, ease: "easeOut" });
    },
  },
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
body {
  @apply bg-black text-white relative;
}
p {
  @apply font-plus text-lg font-normal text-[#FFFFFFCC];
  letter-spacing: 0.00563rem;
}

a {
  @apply text-white font-plus text-base;
}
h1,
.h1 {
  @apply text-white font-aleo text-5xl font-normal;
}
h2,
.h2 {
  @apply text-white font-crash md:text-4xl text-[1.75rem] font-semibold;
  letter-spacing: 0.01em;
}
h3,
.h3 {
  @apply text-white font-aleo md:text-3xl text-[1.55rem] font-bold;
}
h4,
.h4 {
  @apply text-white font-aleo text-2xl font-bold;
}
h5,
.h5 {
  @apply text-white font-crash text-xl font-bold;
}
h6,
.h6 {
  @apply text-white font-plus text-base font-normal;
}
*::-webkit-scrollbar {
  width: 9px;
}
*::-webkit-scrollbar-track {
  @apply bg-black rounded-md;
}
*::-webkit-scrollbar-thumb {
  border: 3px solid;
  @apply bg-black border-red rounded-md cursor-pointer;
}
.smt {
  @apply text-base;
}
* {
  margin: 0;
  padding: 0;
}
.red-btn {
  @apply text-base px-10 py-3 bg-red rounded-[4px]  relative overflow-hidden hover:bg-[transparent] hover:text-red z-[1];
  font-weight: 600;
  /* font-size: 0.8rem; */
  letter-spacing: 0.0075em;
  transition: transform 0.3s ease-out;
  &:before {
    transition: transform 0.3s ease-out;
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: #fff;
    bottom: -70px;
    right: 2rem;
    z-index: -1;
  }
  &::after {
    content: "";
    @apply bg-red absolute top-0 left-0 w-full h-full;
    z-index: -2;
  }
  &:hover {
    &::before {
      transform: scale(73);
    }
  }
}
.yellow-bg-tag {
  @apply bg-yellow text-black inline-block px-2 py-1 font-bold text-xs;
}
footer {
  background: linear-gradient(
    180deg,
    #191919 -29.32%,
    rgba(16, 16, 16, 0) 71.22%
  );
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */
</style>
