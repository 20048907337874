<template>
  <div class="w-full">
    <section class="relative md:min-h-0 min-h-[65vh]">
      <div class="flex flex-nowrap" ref="slideCont">
        <div class="min-w-[100vw] fff1">
          <!-- <img src="@/assets/images/banner.png" class="w-full" alt="" /> -->
          <div
            class="w-full h-full flex md:items-center items-end justify-center"
          >
            <div
              class="max-w-[68.3rem] mx-auto w-[95%] flex md:items-center items-end"
            >
              <div class="content w-full md:w-auto">
                <div class="flex items-center mb-4 gap-3 flex-row">
                  <div class="overflow-hidden">
                    <img src="@/assets/svg/redbolt.svg" class="blt1" alt="" />
                  </div>
                  <div class="overflow-hidden">
                    <h1 class="h1 text-left md:text-[3.3rem] text-[2rem] h11">
                      SUPER<span class="text-red">CHARGE</span>
                    </h1>
                  </div>
                </div>
                <div class="mb-8 overflow-hidden md:w-4/5">
                  <p class="text-left h6 p1">
                    Your EVs for FREE at our Flagship Public Charging Station in
                    Adeola Odeku, V.I
                  </p>
                </div>
                <!-- <div class="flex items-center w-full adbg gap-3 p-4 mb-12">
                  <img src="@/assets/svg/loc.svg" alt="" />
                  <p class="text-left h6">
                    Sterling Bank, Adeola Odeku, Victoria Island, Lagos.
                  </p>
                </div> -->
                <div class="overflow-hidden md:w-auto w-full">
                  <button class="red-btn conBtn md:w-auto w-full btn1">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[100vw] fff2">
          <!-- <img src="@/assets/images/banner2.png" class="w-full" alt="" /> -->
          <div
            class="w-full h-full flex md:items-center items-end justify-center"
          >
            <div
              class="max-w-[68.3rem] mx-auto w-[95%] flex md:items-center items-end"
            >
              <div class="content w-full md:w-auto">
                <div
                  class="flex md:items-center items-start mb-4 gap-3 flex-row"
                >
                  <div class="overflow-hidden">
                    <img class="blt2" src="@/assets/svg/whitebolt.svg" alt="" />
                  </div>
                  <div>
                    <div class="overflow-hidden">
                      <h1 class="h1 text-left md:text-[3.3rem] text-[2rem] h12">
                        Power Moves <span class="text-red">Differently</span>
                      </h1>
                    </div>
                    <div class="mb-8 overflow-hidden sm:hidden block">
                      <p class="text-left h6 mt-4 p2">
                        Electric mobility for the future
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-8 overflow-hidden hidden sm:block">
                  <p class="text-left h6 p2">
                    Electric mobility for the future
                  </p>
                </div>
                <div class="overflow-hidden w-full md:w-auto">
                  <button class="red-btn conBtn btn2 w-full md:w-auto">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[100vw] fff3">
          <!-- <img src="@/assets/images/banner.png" class="w-full" alt="" /> -->
          <div
            class="w-full h-full flex md:items-center items-end justify-center"
          >
            <div
              class="max-w-[68.3rem] mx-auto w-[95%] flex md:items-center items-end"
            >
              <div class="content w-full md:w-auto">
                <div class="flex items-center mb-4 gap-3 flex-row">
                  <div class="overflow-hidden">
                    <img class="blt3" src="@/assets/svg/redbolt.svg" alt="" />
                  </div>
                  <div class="overflow-hidden">
                    <h1 class="h1 text-left md:text-[3.3rem] text-[2rem] h13">
                      SUPER<span class="text-red">CHARGE</span>
                    </h1>
                  </div>
                </div>
                <div class="mb-8 overflow-hidden md:w-4/5">
                  <p class="text-left h6 p3">
                    Your EVs for FREE at our Flagship Public Charging Station in
                    Adeola Odeku, V.I
                  </p>
                </div>
                <!-- <div class="flex items-center w-full adbg gap-3 p-4 mb-12">
                  <img src="@/assets/svg/loc.svg" alt="" />
                  <p class="text-left h6">
                    Sterling Bank, Adeola Odeku, Victoria Island, Lagos.
                  </p>
                </div> -->
                <div class="overflow-hidden md:w-auto w-full">
                  <button class="red-btn conBtn md:w-auto w-full btn3">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="min-w-[100vw] fff4">
          <!-- <img src="@/assets/images/banner2.png" class="w-full" alt="" /> -->
          <div
            class="w-full h-full flex md:items-center items-end justify-center"
          >
            <div
              class="max-w-[68.3rem] mx-auto w-[95%] flex md:items-center items-end"
            >
              <div class="content w-full md:w-auto">
                <div
                  class="flex md:items-center items-start mb-4 gap-3 flex-row"
                >
                  <div class="overflow-hidden">
                    <img class="blt4" src="@/assets/svg/whitebolt.svg" alt="" />
                  </div>
                  <div>
                    <div class="overflow-hidden">
                      <h1 class="h1 text-left md:text-[3.3rem] text-[2rem] h14">
                        Power Moves <span class="text-red">Differently</span>
                      </h1>
                    </div>
                    <div class="mb-8 overflow-hidden sm:hidden mt-4 block">
                      <p class="text-left h6 p4">
                        Electric mobility for the future
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mb-8 overflow-hidden hidden sm:block">
                  <p class="text-left h6 p4">
                    Electric mobility for the future
                  </p>
                </div>
                <div class="overflow-hidden w-full md:w-auto">
                  <button class="red-btn conBtn btn4 w-full md:w-auto">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <img
        src="@/assets/images/banner.png"
        class="
          md:w-full
          w-auto
          md:min-h-0 md:max-h-[none]
          max-h-[65vh] max-w-[none]
          md:relative
          absolute
          top-0
          md:right-0
          right-[-45%]
          md:hidden
        "
        alt=""
      />
      <div class="absolute top-0 left-0 w-full h-full ovlbg">
        <div
          class="
            max-w-[68.3rem]
            m-auto
            w-[95%]
            relative
            flex flex-col
            items-start
            justify-end
            h-full
            md:pb-32
            pb-12
          "
        >
          <div class="content">
            <h1 class="h1 mb-4">Power Moves Differently</h1>
            <p class="mb-8">Electric mobility for the future</p>
            <button class="red-btn conBtn">Contact Us</button>
          </div>
        </div>
      </div> -->
    </section>
    <section class="bg-black w-full md:py-8 py-6 beey md:block hidden">
      <section class="sect max-w-[68.3rem] mx-auto w-[95%] overflow-hidden">
        <div
          class="flex items-center justify-evenly clashSemi fwt text text-center"
        >
          <div
            class="min-w-[300vw] sm:min-w-[65rem] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span>|</span>
          <div
            class="min-w-[300vw] sm:min-w-[65rem] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span>|</span>
          <div
            class="min-w-[300vw] sm:min-w-[65rem] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span>|</span>
          <div
            class="min-w-[300vw] sm:min-w-[65rem] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span>|</span>
          <div
            class="min-w-[300vw] sm:min-w-[65rem] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span>|</span>
          <div
            class="min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
          <span class="md:hidden">|</span>
          <div
            class="md:hidden min-w-[250vw] sm:min-w-[180vw] md:min-w-[68.3rem] md:max-w-[68.3rem] flex items-center justify-evenly"
          >
            <span><a href="#fin" class="clashSemi">FINANCING</a></span>
            <span>|</span>
            <span
              ><a href="#maf" class="clashSemi"
                >MANUFACTURING, ASSEMBLY & SALES</a
              ></span
            >
            <span>|</span>
            <span><a href="#swt" class="clashSemi">SWITCH</a></span>
            <span>|</span>
            <span
              ><a href="#mas" class="clashSemi">MOBILITY AS A SERVICE</a></span
            >
          </div>
        </div>
      </section>
    </section>
    <section class="flex items-center md:h-[80vh] md:py-0 pt-24 misbg">
      <div class="flex items-center justify-end max-w-[68.3rem] m-auto w-[95%]">
        <div class="misvis md:w-1/2 w-full lg:pl-24">
          <div class="ct relative">
            <div class="w-full flex items-start gap-6">
              <div class="overflow-hidden">
                <img
                  class="mb-4 w-[58px] visimg"
                  src="@/assets/svg/video_cam.svg"
                  alt=""
                />
              </div>
              <div class="w-full">
                <div class="overflow-hidden">
                  <h3 class="h3 clashBold vish">Our Vision</h3>
                </div>
                <div class="overflow-hidden mt-3 w-full">
                  <p class="visp">
                    is to be the preferred mobility solutions provider in
                    Africa.
                  </p>
                </div>
              </div>
            </div>
            <div class="relative">
              <img
                class="absolute left-[29px] -top-2 path"
                src="@/assets/svg/path.svg"
                alt=""
              />
            </div>
            <div class="w-full mt-24 flex items-start gap-6">
              <div class="overflow-hidden">
                <img
                  class="mb-4 w-[58px] misimg"
                  src="@/assets/svg/rocket.svg"
                  alt=""
                />
              </div>
              <div class="w-full">
                <div class="overflow-hidden">
                  <h3 class="h3 clashBold mish">Our Mission</h3>
                </div>
                <div class="mt-3 w-full overflow-hidden">
                  <p class="misp">
                    is to accelerate the adoption of clean mobility solutions
                    that enrich lives by delivering quality innovative solutions
                    through community x partnerships.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="
          max-w-[68.3rem]
          m-auto
          w-[95%]
          flex
          items-center
          justify-between
          gap-8
          flex-col
          md:flex-row
        "
      >
        <div class="w-full">
          <img class="mb-4" src="@/assets/svg/rocket.svg" alt="" />
          <h3 class="h3">Our Mission</h3>
          <p class="h6 mt-3 md:w-[95%] lg:w-[65%]">
            is to accelerate the adoption of clean mobility solutions that
            enrich lives by delivering quality innovative solutions through
            community x partnerships.
          </p>
        </div>
        <div class="w-full">
          <img src="@/assets/images/mission.png" alt="" class="w-full" />
        </div>
      </div> -->
      <!-- <img
        class="mt-[-17%] mb-[-5%] hidden md:block"
        src="@/assets/svg/path.svg"
        alt=""
      /> -->
      <!-- <div
        class="
          max-w-[68.3rem]
          m-auto
          w-[95%]
          flex
          items-center
          justify-between
          gap-8
          flex-col-reverse
          md:flex-row md:mt-0
          mt-24
        "
      >
        <div class="w-full">
          <img src="@/assets/images/vision.png" alt="" class="w-full" />
        </div>
        <div class="w-full">
          <img class="mb-4" src="@/assets/svg/video_cam.svg" alt="" />
          <h3 class="h3">Our Vision</h3>
          <p class="h6 mt-3 md:w-[95%] lg:w-[65%]">
            is to be the preferred mobility solutions provider in Africa.
          </p>
        </div>
      </div> -->
    </section>
    <!-- <section class="bg-dgrey py-24 w-full overflow-hidden">
      <section class="sect">
        <div class="flex text">
          MOBILITY AS A SERVICE | FINANCING | SWITCH FINANCING | MOBILITY AS A
          SERVICE | FINANCING | SWITCH FINANCING| MOBILITY AS A SERVICE |
          FINANCING | SWITCH FINANCING | MOBILITY AS A SERVICE | FINANCING |
          SWITCH FINANCING| MOBILITY AS A SERVICE | FINANCING | SWITCH FINANCING
          MOBILITY AS A SERVICE | FINANCING | SWITCH FINANCING | MOBILITY AS A
          SERVICE | FINANCING | SWITCH FINANCING| MOBILITY AS A SERVICE |
          FINANCING | SWITCH FINANCING | MOBILITY AS A SERVICE | FINANCING |
          SWITCH FINANCING| MOBILITY AS A SERVICE | FINANCING | SWITCH FINANCING
          MOBILITY AS A SERVICE | FINANCING | SWITCH FINANCING
        </div>
      </section>
      <section class="sect">
        <div class="flex text">
          MANUFACTURING, ASSEMBLY & SALES | SWITCH | FINANCING | MOBILITY AS A
          SERVICE MANUFACTURING, ASSEMBLY & SALES | SWITCH | FINANCING |
          MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY & SALES | SWITCH |
          FINANCING | MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY & SALES |
          SWITCH | FINANCING | MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY &
          SALES | SWITCH | FINANCING | MOBILITY AS A SERVICE | MANUFACTURING,
          ASSEMBLY & SALES | SWITCH | FINANCING | MOBILITY AS A SERVICE
          MANUFACTURING, ASSEMBLY & SALES | SWITCH | FINANCING | MOBILITY AS A
          SERVICE
        </div>
      </section>
      <section class="sect">
        <div class="flex text">
          SWITCH FINANCING | MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY &
          SALES SWITCH FINANCING | MOBILITY AS A SERVICE | MANUFACTURING,
          ASSEMBLY & SALES SWITCH FINANCING | MOBILITY AS A SERVICE |
          MANUFACTURING, ASSEMBLY & SALES SWITCH FINANCING | MOBILITY AS A
          SERVICE | MANUFACTURING, ASSEMBLY & SALES SWITCH FINANCING | MOBILITY
          AS A SERVICE | MANUFACTURING, ASSEMBLY & SALES SWITCH FINANCING |
          MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY & SALES SWITCH
          FINANCING | MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY & SALES
          SWITCH FINANCING | MOBILITY AS A SERVICE | MANUFACTURING, ASSEMBLY &
          SALES SWITCH
        </div>
      </section>
    </section> -->
    <section id="Services">
      <div class="max-w-[68.3rem] m-auto w-[95%]">
        <div class="lg:w-2/3 md:w-4/5 text-center w-full m-auto py-24">
          <div class="overflow-hidden mb-4">
            <h6 class="h6 smt text-yellow">CHARGING STATION</h6>
          </div>
          <div class="mb-4 overflow-hidden">
            <h2 class="h2">
              Power up your electric vehicles at our flagship charge site
            </h2>
          </div>
          <div class="overflow-hidden">
            <p class="">
              Visit: 300, Adeola Odeku Street, Victoria Island, Lagos, Nigeria.
            </p>
          </div>
          <div class="overflow-hidden">
            <button class="red-btn mt-10 conBtn">Contact Us</button>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full pb-24 gallery">
      <div class="w-full md:flex items-center justify-between statn hidden">
        <div class="w-1/2 overflow-hidden">
          <img
            :src="currentStation.left"
            id="dsk1"
            class="w-full pc hover:scale-110 hover:brightness-125 duration-500"
            alt=""
          />
        </div>
        <div class="w-1/2 overflow-hidden">
          <img
            :src="currentStation.right"
            id="dsk2"
            class="pc w-full hover:scale-110 hover:brightness-125 duration-500"
            alt=""
          />
        </div>
      </div>
      <div
        class="w-full flex items-center justify-center statn md:hidden relative overflow-hidden h-[60vh] mstatn"
      >
        <img
          :src="mcurrentStation"
          class="pc h-[60vh] max-w-[none] absolute top-[-9999px] right-[-9999px] bottom-[-9999px] left-[-9999px] m-auto"
          alt=""
        />
        <div
          class="flex items-center justify-between absolute top-[-9999px] right-[-9999px] bottom-[-9999px] left-[-9999px] mmax-w-[68.3rem] w-[95%] m-auto"
        >
          <img
            src="@/assets/svg/blackarr.svg"
            class="rotate-180 cursor-pointer opacity-60 hover:opacity-100 trans block md:hidden mbbtn"
            @click="mprevStation"
            alt=""
          />
          <img
            src="@/assets/svg/blackarr.svg"
            class="cursor-pointer opacity-60 hover:opacity-100 trans block mbbtn md:hidden"
            alt=""
            @click="mnextStation"
          />
        </div>
      </div>
      <div
        class="mt-12 flex max-w-[68.3rem] w-[95%] m-auto items-center justify-between"
      >
        <div class="w-[8rem]"></div>
        <div
          class="md:w-1/6 w-1/3 m-auto flex items-center justify-center gap-3"
        >
          <!-- <div class="border-t borr w-10/12"></div>
          <div class="border-t borr opacity-60 w-2/12"></div> -->
          <div class="justify-center items-center gap-2 flex md:hidden">
            <span
              v-for="(rrr, index) in mstationPics"
              :key="index"
              class="inline-block h-[2px] bg-[#D9D9D9] trans"
              :class="{
                'w-[15px]': index !== mcrnt,
                'w-[60px]': index === mcrnt,
                'md:w-[20px]': index !== mcrnt,
                'md:w-[150px]': index === mcrnt,
                'opacity-60': index !== mcrnt,
                'opacity-100': index === mcrnt,
              }"
            ></span>
          </div>
          <div class="hidden justify-center items-center gap-2 md:flex">
            <span
              v-for="(rrr, index) in stationPics"
              :key="index"
              class="inline-block h-[2px] bg-[#D9D9D9] trans"
              :class="{
                'w-[15px]': index !== crnt,
                'w-[60px]': index === crnt,
                'md:w-[20px]': index !== crnt,
                'md:w-[150px]': index === crnt,
                'opacity-60': index !== crnt,
                'opacity-100': index === crnt,
              }"
            ></span>
          </div>
        </div>
        <div
          class="md:max-w-[8rem] md:w-auto w-[8rem] flex items-center justify-between gap-8"
        >
          <img
            src="@/assets/svg/arr.svg"
            class="rotate-180 cursor-pointer opacity-60 hover:opacity-100 trans md:block hidden"
            @click="prevStation"
            alt=""
          />
          <img
            src="@/assets/svg/arr.svg"
            class="cursor-pointer opacity-60 hover:opacity-100 trans md:block hidden"
            alt=""
            @click="nextStation"
          />
        </div>
      </div>
    </section>
    <section
      :style="wee"
      class="flex items-center justify-between flex-col md:flex-row gap-12 pb-24 finn"
    >
      <div class="w-full md:w-[60%]">
        <img src="@/assets/images/fin.png" class="w-full" alt="" />
      </div>
      <div class="w-[95%] m-auto md:ml-0 md:w-[40%] overflow-hidden" id="fin">
        <span class="yellow-bg-tag">QORAY FINANCING</span>
        <h2 class="h2 mt-6">Owning an electric vehicle just got easier!</h2>
        <p class="mt-4 md:w-4/5">
          Flexible lease payment for aggregators, cluster groups to aid in
          vehicle ownership
        </p>
        <div class="flex items-center gap-2 mt-5 bal">
          <img src="@/assets/svg/balance.svg" alt="" />
          <span class="">Terms & Conditions apply.</span>
        </div>
        <button class="red-btn mt-8 conBtn">Join the waitlist</button>
      </div>
    </section>
    <section class="flex items-center man lg:min-h-[70vh] h-auto">
      <div class="max-w-[68.3rem] w-[95%] m-auto py-24">
        <div class="md:w-1/2 w-full overflow-hidden" id="maf">
          <span class="yellow-bg-tag">MANUFACTURING, ASSEMBLY & SALES </span>
          <h2 class="h2 mt-6">
            Electric vehicle purchase is now more accessible
          </h2>
          <p class="mt-4">
            Electric vehicles available for dealership, partnership(s) and
            franchising
          </p>
          <button class="red-btn mt-8 conBtn">Join the waitlist</button>
        </div>
      </div>
    </section>
    <section class="w-full overflow-hidden">
      <section class="m-auto max-w-[68.3rem] w-[95%] py-24">
        <div class="w-full">
          <div
            id="swt"
            class="flex items-start justify-between gap-8 flex-col md:flex-row md:items-center"
          >
            <div class="overflow-hidden">
              <span class="yellow-bg-tag">QORAY SWITCH</span>
              <h2 class="h2 mt-6 sm:w-3/4 w-full">
                Convert your old tricycles and minibuses to electric
              </h2>
              <p class="mt-4">Break the norm! Re-invent the wheel...</p>
            </div>
            <button class="red-btn conBtn min-w-[220px]">
              Join the waitlist
            </button>
          </div>
          <div
            class="flex items-center justify-between gap-8 flex-col md:flex-row"
            id="swt2"
          >
            <div class="md:w-2/5 w-full">
              <div class="mt-24">
                <img src="@/assets/images/tri.png" class="w-full tri" alt="" />
                <h5 class="h5 mt-12 trih">Tricycles</h5>
                <p class="mt-4 smt trip">
                  Break the norm! Re-invent the wheel...
                </p>
              </div>
            </div>
            <div class="md:w-3/5 w-full">
              <div>
                <div class="relative">
                  <img
                    src="@/assets/images/bus.png"
                    class="w-full bus"
                    alt=""
                  />
                  <div
                    class="absolute w-[200px] h-[200px] right-[-74px] bottom-[-74px]"
                  >
                    <div class="relative w-full h-full">
                      <div
                        class="absolute top-0 left-0 flex items-center justify-center w-full h-full rotext"
                      >
                        <img
                          src="@/assets/svg/rtext.svg"
                          alt=""
                          class="w-full rotanim2"
                        />
                      </div>
                      <div
                        class="absolute top-0 left-0 flex items-center justify-center w-full h-full rotanim"
                      >
                        <img src="@/assets/svg/rockett.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <h5 class="h5 mt-12 bush">Public & Private Vehicles</h5>
                <p class="mt-4 smt busp">
                  Break the norm! Re-invent the wheel...
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="py-24 phh">
      <div class="bg-[#191919]">
        <div
          class="max-w-[68.3rem] m-auto w-[95%] flex items-center justify-between flex-col md:flex-row py-20 gap-16"
        >
          <div
            class="w-[300px] h-[300px] lg:w-[450px] lg:h-[450px] md:w-[350px] md:h-[350px] rounded-full bg-[#212121] relative"
          >
            <img
              src="@/assets/images/phone.png"
              class="lg:hidden absolute w-full md:top-[-35%] top-[-60%] phimg"
              alt=""
            />
          </div>
          <img
            src="@/assets/images/phone.png"
            class="lg:absolute lg:block hidden phimg"
            alt=""
          />
          <div class="md:w-1/2 overflow-hidden" id="mas">
            <div class="md:w-3/4 w-full">
              <span class="yellow-bg-tag">MOBILITY AS A SERVICE </span>
              <h2 class="h2 mt-6">Rediscover the joys of chauffer service</h2>
              <p class="mt-4">
                Travel just got cooler with any of our e-vehicle models on the
                ride-hailing apps you already love
              </p>
              <button class="red-btn mt-8 conBtn">Join the waitlist</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="max-w-[68.3rem] m-auto w-[95%] sm:py-24 pb-24 pt-0 py-0"
      id="Testimonials"
    >
      <div
        class="w-full flex items-center justify-between flex-col md:flex-row md:gap-8 gap-8 overflow-hidden"
      >
        <div class="md:w-5/12 w-full text-left fromleft">
          <h6 class="h6 text-yellow">TESTIMONIALS</h6>
          <h2 class="h2 mt-4 w-full">Our Customers love what we do</h2>
          <p class="mt-4 md:w-11/12 w-full">
            Don't just take our word for it. Read what some of our customers
            have to say about Qoray.
          </p>
        </div>
        <div class="md:w-7/12 w-full relative scaleup">
          <div class="w-full overflow-hidden">
            <div class="w-full flex items-center justify-between flex-col rvw">
              <div class="w-full overflow-hidden">
                <img
                  class="w-full"
                  :src="`/assets/reviews/${currentReview.img}`"
                  alt=""
                />
              </div>
              <div class="w-full md:ml-12">
                <img
                  src="@/assets/svg/quote.svg"
                  class="mt-8 md:-ml-6 -mb-4"
                  alt=""
                />
                <p class="font-bold md:w-3/4">
                  {{ currentReview.comment }}
                </p>
                <p class="text-yellow mt-4">{{ currentReview.name }}</p>
                <p class="text-yellow mt-1">{{ currentReview.desc }}</p>
                <div class="md:flex items-center gap-2 mt-16 hidden">
                  <span
                    v-for="(rrr, index) in review.data"
                    :key="index"
                    class="inline-block h-[2px] bg-[#D9D9D9] trans"
                    :class="{
                      'w-[15px]': index !== review.current,
                      'w-[60px]': index === review.current,
                      'md:w-[20px]': index !== review.current,
                      'md:w-[150px]': index === review.current,
                      'opacity-60': index !== review.current,
                      'opacity-100': index === review.current,
                    }"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="ctrls flex items-center justify-between gap-4 w-auto md:max-w-[8rem] md:absolute bottom-0 right-0 md:mt-0 mt-8"
          >
            <div class="md:hidden items-center gap-2 flex">
              <span
                v-for="(rrr, index) in review.data"
                :key="index"
                class="inline-block h-[2px] bg-[#D9D9D9] trans"
                :class="{
                  'w-[15px]': index !== review.current,
                  'w-[60px]': index === review.current,
                  'md:w-[20px]': index !== review.current,
                  'md:w-[150px]': index === review.current,
                  'opacity-60': index !== review.current,
                  'opacity-100': index === review.current,
                }"
              ></span>
            </div>
            <div
              class="md:max-w-[8rem] flex items-center justify-between gap-8"
            >
              <img
                src="@/assets/svg/arr.svg"
                class="rotate-180 cursor-pointer opacity-60 hover:opacity-100 trans"
                @click="prevReview"
                alt=""
              />
              <img
                src="@/assets/svg/arr.svg"
                class="cursor-pointer opacity-60 hover:opacity-100 trans"
                alt=""
                @click="nextReview"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="overflow-hidden md:pt-24 md:border-t-[4px] md:border-t-[#1E1E1E] mile"
    >
      <div class="max-w-[68.3rem] m-auto w-[95%]">
        <div class="w-full text-center mb-4">
          <h6 class="h6 text-yellow">MILESTONE</h6>
          <h2 class="h2 mt-4">The path in front of us</h2>
          <p class="mt-3 font-crash">(2022 - 2028)</p>
        </div>
        <div class="w-full">
          <ul class="checks mt-4">
            <li class="hidden md:block">
              <div
                class="w-full relative flex items-stretch justify-between flex-row-reverse flex-nowrap rvr"
              >
                <div class="relative md:w-1/2 w-full sdv pb-8"></div>
                <div class="relative md:w-1/2 w-1/6 mg"></div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between flex-row-reverse flex-nowrap rvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5">
                    Successful completion of our flagship charge station & over
                    500 stations planned.
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/chg.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/chgy.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between md:flex-row flex-row-reverse flex-nowrap nrvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5 md:text-right">
                    Projected injection of 10,000 electric vehicles into the
                    Nigerian market
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/car.svg"
                    class="w-[64px] md:ml-[-32px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/cary.svg"
                    class="w-[64px] md:ml-[-32px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between flex-row-reverse flex-nowrap rvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5">
                    Create over 800 new jobs in 5 years, through assembly lines
                    and charging station infrastructure partnerships
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/users.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/usersy.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <!-- <li>
            <div
              class="
                w-full
                relative
                flex
                items-stretch
                justify-between
                md:flex-row
                flex-row-reverse flex-nowrap
                nrvr
              "
            >
              <div class="relative md:w-1/2 w-full dv">
                <p class="md:w-3/5 md:text-right">
                  Crash the fossil fuel pollution register by over 20% in the
                  next 5 years
                </p>
              </div>
              <div class="relative md:w-1/2 w-1/6 mg">
                <img
                  src="@/assets/svg/chart.svg"
                  class="w-[64px] md:ml-[-32px] ww"
                  alt=""
                />
                <img
                  src="@/assets/svg/charty.svg"
                  class="w-[64px] md:ml-[-32px] yy"
                  alt=""
                />
              </div>
            </div>
          </li> -->
            <li>
              <div
                class="w-full relative flex items-stretch justify-between md:flex-row flex-row-reverse flex-nowrap nrvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5 md:text-right">
                    Normalize the use of electric-powered cars, trains and boats
                    for private and commercial use.
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/bolt.svg"
                    class="w-[64px] md:ml-[-32px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/bolty.svg"
                    class="w-[64px] md:ml-[-32px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between flex-row-reverse flex-nowrap rvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5">
                    Skyrocket transport margins and crash transport costs to
                    combat the removal of fuel subsidy
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/coins.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/coinsy.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between md:flex-row flex-row-reverse flex-nowrap nrvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5 md:text-right">
                    Finance and ease the entry point for 2,000 franchises and
                    aggregators
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/shake.svg"
                    class="w-[64px] md:ml-[-32px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/shakey.svg"
                    class="w-[64px] md:ml-[-32px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                class="w-full relative flex items-stretch justify-between flex-row-reverse flex-nowrap rvr"
              >
                <div class="relative md:w-1/2 w-full dv">
                  <p class="md:w-3/5">
                    Galvanize effort on the cumulative impact of the levers of
                    air pollution to generate $3.66Bn
                  </p>
                </div>
                <div class="relative md:w-1/2 w-1/6 mg">
                  <img
                    src="@/assets/svg/moneybag.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] ww"
                    alt=""
                  />
                  <img
                    src="@/assets/svg/moneybagy.svg"
                    class="w-[64px] sm:mr-[-32px] mr-[-25px] yy"
                    alt=""
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="w-0 h-0 overflow-hidden">
      <img
        v-for="(img, index) in mstationPics"
        :key="`index${index}index`"
        :src="img"
        alt=""
      />
      <img
        v-for="(img, index) in review.data"
        :key="`index${index}`"
        :src="`/assets/reviews/${img.img}`"
        alt=""
      />
    </section>
    <footer class="pt-24">
      <div class="max-w-[68.3rem] m-auto w-[95%] pb-20" id="Newsletter">
        <div class="w-full text-center">
          <h2 class="h2">Sign up for our Newsletter</h2>
          <p class="lg:w-1/4 md:w-1/3 sm:w-2/3 w-[95%] m-auto mt-4 smt">
            Join the community today to learn more about how to move
            differently.
          </p>
          <div class="w-full lg:w-1/2 md:w-3/5 m-auto mt-12">
            <div
              class="w-full md:flex-row flex flex-col items-center justify-between gap-4"
            >
              <input
                class="px-4 py-3 bg-[#303030] outline-none rounded-[8px] w-full"
                type="email"
                placeholder="Email Address"
                v-model="email"
              />
              <button
                @click="Subscribe"
                class="red-btn min-w-[170px] w-full md:w-auto"
              >
                {{ loading ? "Loading..." : "Subscribe" }}
              </button>
            </div>
            <p v-if="success" class="text-green text-xs">
              Subscribed successfully
            </p>
            <p v-if="err" class="text-red text-xs">{{ err }}</p>
          </div>
          <hr
            class="w-full border-t border-white border-dashed mt-16 mb-16 opacity-20"
          />
          <!-- <div
            class="socials w-auto m-auto flex items-center justify-center gap-4"
          >
            <a
              class="insta"
              href="https://www.instagram.com/qoremobility/"
              target="__blank"
            >
              <img src="@/assets/svg/insta.svg" alt="" />
            </a>
            <a
              class="fb"
              href="https://web.facebook.com/profile.php?id=100092283964552"
              target="__blank"
            >
              <img src="@/assets/svg/fb.svg" alt="" />
            </a>
            <a
              class="linkedin"
              href="https://www.linkedin.com/in/qore-mobility-52a753273/"
              target="__blank"
            >
              <img src="@/assets/svg/linkedin.svg" alt="" />
            </a>
            <a class="twit" href="" target="__blank">
              <img src="@/assets/svg/twit.svg" alt="" />
            </a>
          </div> -->
          <div class="flex items-center justify-center mt-6">
            <a href="mailto:qoray@sterling.ng">qoray@sterling.ng</a>
          </div>
          <div class="powers flex items-center justify-center gap-4 smt mt-4">
            <span class="opacity-80">Powered By:</span>
            <span>
              <img src="@/assets/svg/sterling.svg" alt="" />
            </span>
            <span> | </span>
            <span>
              <img src="@/assets/svg/imperium.svg" alt="" />
            </span>
          </div>
        </div>
      </div>
      <img src="@/assets/images/base.png" alt="" class="w-full" />
    </footer>
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import qorel1 from "@/assets/images/qorel.jpg";
import qorel2 from "@/assets/images/qorel2.jpg";
// import qorel3 from "@/assets/images/qorel3.jpg";
import qorer1 from "@/assets/images/qorer.jpg";
import qorer2 from "@/assets/images/qorer2.jpg";
// import qorer3 from "@/assets/images/qorer3.jpg";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "HomeView",
  data() {
    return {
      scr: window.innerWidth,
      email: "",
      err: "",
      loading: "",
      success: "",
      rvrs: false,
      crnt: 0,
      stationPics: [
        { left: qorel1, right: qorer1 },
        { left: qorel2, right: qorer2 },
        // { left: qorel3, right: qorer3 },
      ],
      mcrnt: 0,
      // mstationPics: [qorel1, qorer1, qorel2, qorer2, qorel3, qorer3],
      mstationPics: [qorel1, qorer1, qorel2, qorer2],
      review: {
        data: [
          {
            img: "willi.png",
            name: "Hajiya Habiba Abubakar Imam",
            desc: "Yar Bawai Women Cooperative Chairlady,",
            comment:
              "With the level of acceptance by leaders in the state, the number of female Electric Vehicle riders in Kano will quadruple in the nearest future.",
          },
          {
            img: "willi2.png",
            name: "Hajiya Hauwa Ahmad Tarauni",
            desc: "Mata Zalla Women Cooperative Chairlady,",
            comment:
              "This is a huge economic blessing for women and their families in Kano state.",
          },
          // {
          //   img: "will.png",
          //   name: "Olamilekan Oluwaferi",
          //   desc: "Team Lead",
          //   comment:
          //     "We've designed advanced safety features into every element of Qoray. Including an innovative airbag that inflates between riders to keep everyone separate and protected.",
          // },
          // {
          //   img: "will2.png",
          //   name: "Sandra Chijioke",
          //   desc: "Team Lead",
          //   comment:
          //     "The product is good. Even the way they helped me with the payment plan was very friendly - like they want me to be happy x grow my transport business.",
          // },
          // {
          //   img: "will3.png",
          //   name: "Orji Oduwole",
          //   desc: "Team Lead",
          //   comment:
          //     "I am able to give my Oga more money on a weekly basis, thanks to Qoray. I support the product die!",
          // },
        ],
        current: 0,
      },
    };
  },
  computed: {
    wee() {
      if (this.scr > 1440) {
        let tenp = ((10 / 100) * 1440) / 2;
        return `width: ${(this.scr - 1440) / 2 + 1440 - tenp}px;`;
      } else if (this.scr > 570) {
        let tenp = ((10 / 100) * this.scr) / 2;
        return `width: ${this.scr - tenp}px;`;
      } else {
        return "width: 100%";
      }
    },
    currentReview() {
      return this.review.data[this.review.current];
    },
    currentStation() {
      return this.stationPics[this.crnt];
    },
    mcurrentStation() {
      return this.mstationPics[this.mcrnt];
    },
  },
  methods: {
    Subscribe() {
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.email.match(rege)) {
        this.err = "Invalid email";
        return false;
      } else {
        this.loading = true;
        fetch(process.env.VUE_APP_API_URL + "mails/data.json", {
          method: "POST",
          body: JSON.stringify(this.email),
        })
          .then((res) => res.json())
          .then((response) => {
            console.log(response);
            this.success = true;
            this.loading = false;
            this.email = "";
          });
      }
    },
    carousel() {
      // var tl = gsap.timeline({
      //   delay: 0.8,
      //   onComplete: () => {
      // var dem = document.getElementsByClassName("hoverDrop");
      // dem = Array.from(dem);
      // dem.forEach((element) => {
      //   element.classList.remove("norm");
      // });

      gsap.to(".fff1", {
        x: "-100%",
        duration: 1.5,
        ease: "power3.out",
      });
      gsap.to(".fff2", {
        x: "-100%",
        duration: 1.5,
        ease: "power3.out",
        onComplete: () => {
          gsap.to(".fff3", {
            x: "-100%",
          });
          gsap.set(".fff4", { x: "0" });
          gsap.set(".blt3", { yPercent: 120 });
          gsap.set(".h13", { yPercent: 100 });
          gsap.set(".p3", { yPercent: 100 });
          gsap.set(".btn3", { yPercent: 100 });
          setTimeout(() => {
            this.carousel2();
          }, 3000);
        },
      });
      var tl = gsap.timeline({
        delay: 1.4,
      });
      tl.to(".blt2", { yPercent: 0, ease: "power3.out", duration: 1 }, "-=0.7")
        .to(".h12", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.5")
        .to(".p2", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.7")
        .to(
          ".btn2",
          { yPercent: 0, ease: "power3.out", duration: 1.2 },
          "-=0.5"
        );
      // },
      // });
      // tl.to(".btn1", { yPercent: 100, ease: "power3.out", duration: 1.2 })
      //   .to(
      //     ".p1",
      //     { yPercent: 100, ease: "power3.out", duration: 0.8 },
      //     "-=0.7"
      //   )
      //   .to(
      //     ".h11",
      //     { yPercent: 100, ease: "power3.out", duration: 0.8 },
      //     "-=0.5"
      //   )
      //   .to(
      //     ".blt1",
      //     { yPercent: 120, ease: "power3.out", duration: 0.8 },
      //     "-=0.7"
      //   );
    },
    carousel2() {
      gsap.to(".fff2", {
        x: "-200%",
        duration: 1.5,
        ease: "power3.out",
      });
      gsap.to(".fff3", {
        x: "-200%",
        duration: 1.5,
        ease: "power3.out",
        onComplete: () => {
          gsap.to(".fff4", {
            x: "-200%",
          });
          gsap.set(".fff1", { x: "200%" });
          gsap.set(".blt4", { yPercent: 120 });
          gsap.set(".h14", { yPercent: 100 });
          gsap.set(".p4", { yPercent: 100 });
          gsap.set(".btn4", { yPercent: 100 });
          setTimeout(() => {
            this.carousel3();
          }, 3000);
        },
      });
      var tl = gsap.timeline({
        delay: 1.4,
      });
      tl.to(".blt3", { yPercent: 0, ease: "power3.out", duration: 1 }, "-=0.7")
        .to(".h13", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.5")
        .to(".p3", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.7")
        .to(
          ".btn3",
          { yPercent: 0, ease: "power3.out", duration: 1.2 },
          "-=0.5"
        );
    },

    carousel3() {
      gsap.to(".fff3", {
        x: "-300%",
        duration: 1.5,
        ease: "power3.out",
      });
      gsap.to(".fff4", {
        x: "-300%",
        duration: 1.5,
        ease: "power3.out",
        onComplete: () => {
          gsap.to(".fff1", {
            x: "100%",
          });
          gsap.set(".fff2", { x: "200%" });
          gsap.set(".blt1", { yPercent: 120 });
          gsap.set(".h11", { yPercent: 100 });
          gsap.set(".p1", { yPercent: 100 });
          gsap.set(".btn1", { yPercent: 100 });
          setTimeout(() => {
            this.carousel4();
          }, 3000);
        },
      });
      var tl = gsap.timeline({
        delay: 1.4,
      });
      tl.to(".blt4", { yPercent: 0, ease: "power3.out", duration: 1 }, "-=0.7")
        .to(".h14", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.5")
        .to(".p4", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.7")
        .to(
          ".btn4",
          { yPercent: 0, ease: "power3.out", duration: 1.2 },
          "-=0.5"
        );
    },
    carousel4() {
      gsap.to(".fff4", {
        x: "-400%",
        duration: 1.5,
        ease: "power3.out",
      });
      gsap.to(".fff1", {
        x: "0%",
        duration: 1.5,
        ease: "power3.out",
        onComplete: () => {
          gsap.to(".fff2", {
            x: "0%",
          });
          gsap.set(".fff3", { x: "100%" });
          gsap.set(".blt2", { yPercent: 120 });
          gsap.set(".h12", { yPercent: 100 });
          gsap.set(".p2", { yPercent: 100 });
          gsap.set(".btn2", { yPercent: 100 });
          setTimeout(() => {
            this.carousel();
          }, 3000);
        },
      });
      var tl = gsap.timeline({
        delay: 1.4,
      });
      tl.to(".blt1", { yPercent: 0, ease: "power3.out", duration: 1 }, "-=0.7")
        .to(".h11", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.5")
        .to(".p1", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.7")
        .to(
          ".btn1",
          { yPercent: 0, ease: "power3.out", duration: 1.2 },
          "-=0.5"
        );
    },
    reverseAnim() {
      gsap.utils.toArray("section.sect").forEach((section, index) => {
        const w = section.querySelector(".text");
        if (this.rvrs) {
          var [x, xEnd] = !(index % 2)
            ? ["0%", w.scrollWidth * -1]
            : [w.scrollWidth * -1, 0];
          this.rvrs = false;
        } else {
          var [x, xEnd] = !(index % 2)
            ? [w.scrollWidth * -1, 0]
            : ["0%", w.scrollWidth * -1];
          this.rvrs = true;
        }
        gsap.fromTo(
          w,
          { x },
          {
            x: xEnd,
            duration: 360,
            scrollTrigger: {
              trigger: section,
              // end: "top top",
              // scrub: 0.5,
            },
            onComplete: this.reverseAnim,
          }
        );
      });
    },
    nextReview() {
      gsap.to(".rvw", {
        // x: "-100%",
        scale: 1.5,
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          gsap.set(".rvw", {
            scale: 0.5,
            // x: "100%",
            onComplete: () => {
              var length = this.review.data.length;
              if (this.review.current === length - 1) {
                this.review.current = 0;
              } else {
                this.review.current = this.review.current + 1;
              }
              gsap.to(".rvw", {
                // x: "0%",
                scale: 1,
                opacity: 1,
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    prevReview() {
      gsap.to(".rvw", {
        scale: 0.5,
        opacity: 0,
        // x: "100%",
        duration: 0.2,
        onComplete: () => {
          gsap.set(".rvw", {
            scale: 1.5,
            // x: "-100%",
            onComplete: () => {
              var length = this.review.data.length;
              if (this.review.current === 0) {
                this.review.current = length - 1;
              } else {
                this.review.current = this.review.current - 1;
              }
              gsap.to(".rvw", {
                scale: 1,
                opacity: 1,
                // x: "0%",
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    mnextStation() {
      gsap.to(".mstatn", {
        // x: "-100%",
        // scale: 1.5,
        opacity: 0.1,
        duration: 0.1,
        onComplete: () => {
          gsap.set(".mstatn", {
            // scale: 0.5,
            // x: "100%",
            onComplete: () => {
              var length = this.mstationPics.length;
              if (this.mcrnt === length - 1) {
                this.mcrnt = 0;
              } else {
                this.mcrnt = this.mcrnt + 1;
              }
              gsap.to(".mstatn", {
                // x: "0%",
                // scale: 1,
                opacity: 1,
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    mprevStation() {
      gsap.to(".mstatn", {
        // scale: 0.5,
        opacity: 0.1,
        // x: "100%",
        duration: 0.1,
        onComplete: () => {
          gsap.set(".mstatn", {
            // scale: 1.5,
            // x: "-100%",
            onComplete: () => {
              var length = this.mstationPics.length;
              if (this.mcrnt === 0) {
                this.mcrnt = length - 1;
              } else {
                this.mcrnt = this.mcrnt - 1;
              }
              gsap.to(".mstatn", {
                // scale: 1,
                opacity: 1,
                // x: "0%",
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    nextStation() {
      gsap.to(".statn", {
        // x: "-100%",
        // scale: 1.5,
        opacity: 0.1,
        duration: 0.1,
        onComplete: () => {
          gsap.set(".statn", {
            // scale: 0.5,
            // x: "100%",
            onComplete: () => {
              var length = this.stationPics.length;
              if (this.crnt === length - 1) {
                this.crnt = 0;
              } else {
                this.crnt = this.crnt + 1;
              }
              gsap.to(".statn", {
                // x: "0%",
                // scale: 1,
                opacity: 1,
                duration: 0.5,
              });
            },
          });
        },
      });
    },
    prevStation() {
      gsap.to(".statn", {
        // scale: 0.5,
        opacity: 0.1,
        // x: "100%",
        duration: 0.1,
        onComplete: () => {
          gsap.set(".statn", {
            // scale: 1.5,
            // x: "-100%",
            onComplete: () => {
              var length = this.stationPics.length;
              if (this.crnt === 0) {
                this.crnt = length - 1;
              } else {
                this.crnt = this.crnt - 1;
              }
              gsap.to(".statn", {
                // scale: 1,
                opacity: 1,
                // x: "0%",
                duration: 0.5,
              });
            },
          });
        },
      });
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.scr = window.innerWidth;
    });
    gsap.utils.toArray("section.sect").forEach((section, index) => {
      const w = section.querySelector(".text");
      const [x, xEnd] = !(index % 2)
        ? ["0%", w.scrollWidth * -1]
        : [w.scrollWidth * -1, 0];
      gsap.fromTo(
        w,
        { x },
        {
          x: xEnd,
          duration: 360,
          // scrollTrigger: {
          //   trigger: section,
          //   // end: "top top",
          //   // scrub: 0.5,
          // },
          onComplete: this.reverseAnim,
        }
      );
    });
    gsap.set(".fff4", { x: "-400%" });
    gsap.set(".blt1", { yPercent: 120 });
    gsap.set(".h11", { yPercent: 100 });
    gsap.set(".p1", { yPercent: 100 });
    gsap.set(".btn1", { yPercent: 100 });
    var tl = gsap.timeline({
      delay: 1,
      onComplete: () => {
        gsap.set(".blt2", { yPercent: 120 });
        gsap.set(".h12", { yPercent: 100 });
        gsap.set(".p2", { yPercent: 100 });
        gsap.set(".btn2", { yPercent: 100 });
      },
    });
    tl.to(".blt1", { yPercent: 0, ease: "power3.out", duration: 1 }, "-=0.7")
      .to(".h11", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.5")
      .to(".p1", { yPercent: 0, ease: "power3.out", duration: 0.8 }, "-=0.7")
      .to(".btn1", { yPercent: 0, ease: "power3.out", duration: 1.2 }, "-=0.5");
    setTimeout(() => {
      this.carousel();
    }, 3000);

    // OTHER ANIMATIONS+========================
    var misvisTL = gsap.timeline({
      scrollTrigger: {
        trigger: ".misbg",
        start: "top 40%",
      },
    });
    misvisTL
      .from(".visimg", {
        yPercent: -100,
        duration: 1,
        ease: "power3.out",
      })
      .from(".path", { opacity: 0, ease: "power3.out", duration: 0.7 }, "-=0.7")
      .from(
        ".misimg",
        { yPercent: -100, opacity: 0, duration: 0.7, ease: "power3.out" },
        "-=0.5"
      )
      .from(
        ".vish",
        { yPercent: -100, opacity: 0, duration: 0.8, ease: "power3.out" },
        "-=0.5"
      )
      .from(
        ".visp",
        { yPercent: -100, opacity: 0, duration: 0.8, ease: "power3.out" },
        "-=0.5"
      )
      .from(
        ".mish",
        { yPercent: -100, opacity: 0, duration: 0.8, ease: "power3.out" },
        "-=0.5"
      )
      .from(
        ".misp",
        { yPercent: -100, opacity: 0, duration: 1.2, ease: "power3.out" },
        "-=0.5"
      );
    var serTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#Services",
        start: "top 40%",
      },
    });
    serTL
      .from("#Services .h6", {
        yPercent: 100,
        duration: 1,
        ease: "back.out",
      })
      .from(
        "#Services .h2",
        { yPercent: 100, opacity: 0, ease: "back.out", duration: 1.2 },
        "-=0.7"
      )
      .from(
        "#Services p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#Services button",
        { opacity: 0, duration: 0.8, ease: "back.out" },
        "-=0.5"
      );

    gsap.from(".gallery .pc", {
      opacity: 0,
      // scale: 0,
      duration: 1,
      ease: "power3.out",
      onComplete: () => {
        return
        document.getElementById("dsk1").classList.add("transition-all");
        document.getElementById("dsk2").classList.add("transition-all");
      },
      scrollTrigger: {
        trigger: ".gallery",
        start: "top 40%",
      },
    });
    gsap.from(".gallery .mbbtn", {
      opacity: 0,
      delay: 0.2,
      duration: 1,
      ease: "power3.out",
      scrollTrigger: {
        trigger: ".gallery",
        start: "top 40%",
      },
    });
    var finTL = gsap.timeline({
      scrollTrigger: {
        trigger: ".finn",
        start: "top 40%",
      },
    });
    finTL
      .from(".finn img.w-full", {
        opacity: 0,
        duration: 1,
        ease: "power3.out",
      })
      .from(
        ".finn .yellow-bg-tag",
        { xPercent: -100, opacity: 0, ease: "back.out", duration: 1.2 },
        "-=0.7"
      )
      .from(
        ".finn .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".finn p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".finn .bal img",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".finn .bal span",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.6"
      )
      .from(
        ".finn button",
        { opacity: 0, duration: 0.8, ease: "back.out" },
        "-=0.5"
      );
    var mafTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#maf",
        start: "top 40%",
      },
    });
    mafTL
      .from("#maf .yellow-bg-tag", {
        xPercent: -100,
        opacity: 0,
        ease: "back.out",
        duration: 1.2,
      })
      .from(
        "#maf .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#maf p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#maf button",
        { opacity: 0, duration: 0.8, ease: "back.out" },
        "-=0.5"
      );
    var swtTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#swt",
        start: "top 40%",
      },
    });
    swtTL
      .from("#swt .yellow-bg-tag", {
        xPercent: -100,
        opacity: 0,
        ease: "back.out",
        duration: 1.2,
      })
      .from(
        "#swt .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#swt p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#swt button",
        { opacity: 0, duration: 0.8, ease: "back.out" },
        "-=0.5"
      );
    var swt2TL = gsap.timeline({
      scrollTrigger: {
        trigger: "#swt2",
        start: "top 10%",
      },
    });
    swt2TL
      .from("#swt2 .tri", {
        scale: 0.8,
        opacity: 0,
        ease: "back.out",
        duration: 1.2,
      })
      .from(
        "#swt2 .bus",
        {
          scale: 0.8,
          opacity: 0,
          ease: "back.out",
          duration: 1.2,
        },
        "-=1"
      )
      .from(
        "#swt2 .h5",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#swt2 p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )

      .from(
        "#swt2 .rotanim",
        { scale: 0.3, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#swt2 .rotanim2",
        { opacity: 0, rotate: -720, duration: 3, ease: "back.out" },
        "-=0.5"
      );
    var pTL = gsap.timeline({
      scrollTrigger: {
        trigger: ".phh",
        start: "top 40%",
      },
    });
    pTL
      .from(".phh .phimg", {
        scale: 0,
        // opacity: 0,
        ease: "back.out",
        duration: 1.2,
      })
      .from(
        ".phh .yellow-bg-tag",
        {
          xPercent: -100,
          ease: "back.out",
          duration: 0.8,
        },
        "-=0.5"
      )
      .from(
        ".phh .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".phh p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".phh button",
        { opacity: 0, duration: 0.8, ease: "back.out" },
        "-=0.5"
      );
    var TestimonialsTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#Testimonials",
        start: "top 20%",
      },
    });
    TestimonialsTL.from("#Testimonials .fromleft", {
      xPercent: -100,
      opacity: 0,
      ease: "back.out",
      duration: 1.2,
    })
      .from(
        "#Testimonials .scaleup",
        {
          xPercent: -100,
          ease: "back.out",
          duration: 0.8,
        },
        "-=0.8"
      )
      .from(
        "#Testimonials .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        "#Testimonials p",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      );
    var milesTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".mile",
        start: "top 40%",
      },
    });
    milesTl
      .from(".mile .text-yellow", {
        yPercent: 100,
        opacity: 0,
        ease: "back.out",
        duration: 1,
      })
      .from(
        ".mile .h2",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      )
      .from(
        ".mile p.font-crash",
        { yPercent: 100, opacity: 0, duration: 0.7, ease: "back.out" },
        "-=0.5"
      );
    gsap.utils.toArray(".mile li").forEach((stone, index) => {
      if (index > 0) {
        var p = stone.getElementsByClassName("dv");
      } else {
        var p = stone.getElementsByClassName("sdv");
      }
      var img = stone.getElementsByClassName("mg");
      milesTl
        .from(
          img,
          {
            yPercent: 100,
            opacity: 0,
            duration: index === 0 ? 0.1 : 0.8,
            ease: "power3.out",
          },
          "-=0.3"
        )
        .from(
          p,
          { yPercent: 100, opacity: 0, duration: 0.8, ease: "power3.out" },
          "-=0.5"
        );
    });
    milesTl.from("footer #Newsletter", {
      yPercent: 10,
      opacity: 0,
      ease: "back.out",
      duration: 0.8,
    });
  },
};
</script>
<style lang="scss" scoped>
.beey {
  border-top: 1px solid rgba(255, 255, 255, 0.32);
  border-bottom: 1px solid rgba(255, 255, 255, 0.32);
}
.fwt {
  color: #ffffffcc;
}
.clashBold {
  font-family: "ClashDisplay-Bold";
}
.clashSemi {
  font-family: "ClashDisplay-Semibold";
  letter-spacing: 0.08em;
}
.misbg {
  @media (min-width: 786px) {
    background-image: url("@/assets/images/misvis.png");
  }
  @apply bg-black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}
.adbg {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: 1px solid #ffffff21;
  /* border: 1px solid; */

  /* border-image-source: linear-gradient(
    94.37deg,
    rgba(255, 255, 255, 0.32) -36.64%,
    rgba(255, 255, 255, 0) 84.92%
  ); */
}
.fff1,
.fff3 {
  background-image: url("@/assets/images/banner.png");
  @apply md:min-h-[95vh] min-h-[89vh] md:pb-0 pb-4;
  background-size: cover;
  @media (min-width: 786px) {
    /* background-image: url("@/assets/images/misvis.png"); */
    background-position: right center;
  }
  background-position: 66% center;
  img {
    @apply sm:w-auto w-7;
  }
  h1 {
    font-family: "ClashDisplay-Bold";
    letter-spacing: 0.08em;
    /* font-size: 3.3rem; */
  }
}
.fff2,
.fff4 {
  background-image: url("@/assets/images/banner2.png");
  @apply md:min-h-[95vh] min-h-[89vh] md:pb-0 pb-4;
  background-size: cover;
  img {
    @apply sm:w-auto w-7;
  }
  h1 {
    font-family: "ClashDisplay-Semibold";
    letter-spacing: 0.03em;
    /* font-size: 3.3rem; */
  }
}
.flex100 {
  flex: 100%;
}
.ovlbg {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 18.74%,
    rgba(0, 0, 0, 0.7) 122.33%
  );
}
.flex.text {
  /* font-size: clamp(8rem, 15vw, 16rem); */
  /* line-height: 1; */
  /* font-weight: 900; */
  white-space: nowrap;
  /* font-weight: 800; */
  /* font-size: 5rem; */
  /* line-height: 60px; */
  /* letter-spacing: 0.08em; */
  /* color: #fff; */
  /* -webkit-text-fill-color: transparent; Will override color (regardless of order)  */
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: #fff; */
}
.sect {
  @apply md:my-0;
}
.borr {
  border-top-color: #d9d9d9;
}
.man {
  background-image: url("@/assets/images/manu.png");
  background-size: cover;
  background-position: center center;
}
@keyframes rott {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  40% {
    transform: rotate(-90deg);
  }
  60% {
    transform: rotate(90deg);
  }
  80% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.rotext {
  animation: rott ease-out 10s infinite;
}
.trans {
  transition: all 0.3s ease-out;
}
.rvr,
.nrvr {
  /* * {
  } */
  p {
    transition: all 0.3s ease-out;
  }
  img {
    transition: all 0.3s ease-out;
    &.yy {
      display: none;
    }
  }
  &:hover {
    img {
      display: none;
      &.yy {
        display: block;
        .fff1,
        .fff3 {
          background-image: url("@/assets/images/banner.png");
        }
        transform: scale(1.2);
      }
    }
  }
}
.rvr {
  &:hover {
    p {
      transform: scale(1.1);
      @apply text-yellow ml-6;
    }
  }
}
.nrvr {
  &:hover {
    p {
      transform: scale(1.1);
      @apply text-yellow mr-6;
    }
  }
}
.rvr {
  .dv {
    border: none;
    border-left: 1px dashed #fff;
    @apply pb-24 pl-12;
  }
  .sdv {
    border: none;
    border-left: 1px dashed #fff;
    @apply pb-8 pl-12;
  }
  .mg {
    @apply flex justify-end items-start;
  }
}
.nrvr {
  .dv {
    border: none;
    border-left: 1px dashed #fff;
    @apply pb-24 pl-12;
  }
  .mg {
    @apply flex justify-end items-start;
    img {
      @apply sm:mr-[-32px] mr-[-25px];
    }
  }
}
@media (min-width: 786px) {
  .nrvr {
    /* .dv {
    border: none;
    border-left: 1px dashed #fff;
  } */

    .dv {
      border: none;
      border-right: 1px dashed #fff;
      padding: 0;
      @apply pb-24 pr-12;
      @apply flex justify-end items-start;
      margin-left: 2px;
    }
    .mg {
      display: block;
      @apply mr-0;
    }
  }
}
a {
  img {
    transition: all 0.3s ease-out;
    overflow: hidden;
    @apply rounded-full;
  }
  &.insta:hover {
    img {
      background: linear-gradient(
        157.89deg,
        #525bca 10.51%,
        #c837ac 41.38%,
        #ff7645 68.69%,
        #ffc453 100.88%
      );
    }
  }
  &.fb:hover {
    img {
      background: #4267b2;
    }
  }
  &.linkedin:hover {
    img {
      background: #0077b5;
    }
  }
  &.twit:hover {
    img {
      background: #1da1f2;
    }
  }
}
</style>