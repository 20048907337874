import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SubscriberList from '../views/SubscriberList.vue'
import SterlingHuman from '../views/SterlingHuman.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chargestation-subscriber-list',
    name: 'chargestation-subscriber-list',
    component: SubscriberList
  },
  {
    path: '/sterling-human',
    name: 'sterling-human',
    component: SterlingHuman
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((a, b) => {
//   window.location.href = "https://qoraymobility.com";
// })


export default router
