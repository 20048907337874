<template>
  <div class="w-full">
    <!-- <div class="w-full">
      <marquee-text :duration="30" class="mar" :repeat="2">
        Evolution in motion: Qoray transforms into Qoray. Same vision, new name,
        endless possibilities.
      </marquee-text>
    </div> -->
    <nav
      class="max-w-[68.3rem] w-[90%] m-auto flex items-center justify-between py-3 overflow-hidden"
    >
      <img src="@/assets/images/logo_qoray.png" alt="Logo" />
      <div class="md:flex items-center gap-6 hidden">
        <a href="#Services">Services</a>
        <a href="#Testimonials">Testimonials</a>
        <a class="cursor-pointer" @click.prevent="downloadFile"
          >Download Brochure</a
        >
      </div>
      <button class="md:block hidden red-btn conBtn">Contact Us</button>
      <div
        class="cursor-pointer ham md:hidden flex items-start justify-between gap-1 flex-col"
        @click="openNav"
      >
        <span class="block h-[2px] w-[30px] bg-white rounded-sm"></span>
        <span class="block h-[2px] w-[30px] bg-white rounded-sm"></span>
        <span class="block h-[2px] w-[30px] bg-white rounded-sm"></span>
      </div>
      <section
        v-if="isMobile"
        class="mobileMenu fixed top-0 left-0 w-full h-full bg-black z-50"
      >
        <div class="w-[90%] m-auto pt-8">
          <div class="flex items-center justify-end">
            <div
              @click="closeNav"
              class="relative cursor-pointer w-[30px] h-[30px]"
            >
              <span
                class="w-full absolute top-[15px] left-0 block h-[2px] bg-white rotate-45"
              ></span>
              <span
                class="w-full absolute top-[15px] left-0 block h-[2px] bg-white -rotate-45"
              ></span>
            </div>
          </div>
          <div
            @click="closeNav"
            class="mt-8 flex flex-col items-center justify-between gap-8"
          >
            <div class="flex justify-between flex-col items-center gap-6">
              <a href="#Services">Services</a>
              <a href="#Testimonials">Testimonials</a>
              <a class="cursor-pointer" @click.prevent="downloadFile"
                >Download Brochure</a
              >
            </div>
            <div class="flex items-center justify-center">
              <button class="block red-btn conBtn">Contact Us</button>
            </div>
          </div>
        </div>
      </section>
      <section
        class="bg-black conForm fixed z-50 top-0 right-0 max-w-[100%] min-w-[330px]"
      >
        <div class="w-full">
          <div class="bg-red w-full">
            <div class="m-auto w-[90%] py-4">
              <div class="w-full flex items-start justify-between">
                <div>
                  <p class="smt mb-2">Contact Us</p>
                  <h3 class="h3">Reach out to us</h3>
                </div>
                <div>
                  <span
                    class="cursor-pointer inline-flex items-center justify-center w-[50px] h-[50px] bg-[#FFFFFF26] rounded-full"
                    @click="closeCon"
                  >
                    <div class="relative cursor-pointer w-[16px] h-[16px]">
                      <span
                        class="w-full absolute top-[8px] left-0 block h-[2px] bg-white rotate-45"
                      ></span>
                      <span
                        class="w-full absolute top-[8px] left-0 block h-[2px] bg-white -rotate-45"
                      ></span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="m-auto w-[90%] py-4 relative">
            <form
              class="max-h-[70vh] sm:max-h-[80vh] lg:max-h-[80vh] overflow-y-auto w-full max-w-[330px] m-auto cform"
              @submit.prevent="submitForn"
            >
              <div class="mb-4">
                <input
                  type="text"
                  placeholder="Name*"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.name"
                  :class="{
                    err: errs.name.length,
                  }"
                />
                <p class="mt-1 text-red text-xs">{{ errs.name }}</p>
              </div>
              <div class="mb-4">
                <input
                  type="text"
                  placeholder="Email address*"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.email"
                  :class="{
                    err: errs.email.length,
                  }"
                />
                <p class="mt-1 text-red text-xs">{{ errs.email }}</p>
              </div>
              <div class="mb-4">
                <input
                  type="number"
                  placeholder="Phone number*"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.phone"
                  :class="{
                    err: errs.phone.length,
                  }"
                />
                <p class="mt-1 text-red text-xs">{{ errs.phone }}</p>
              </div>
              <div class="mb-4">
                <input
                  type="text"
                  placeholder="Organization*"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.organization"
                  :class="{
                    err: errs.organization.length,
                  }"
                />
                <p class="mt-1 text-red text-xs">{{ errs.organization }}</p>
              </div>
              <div class="mb-4">
                <input
                  type="text"
                  placeholder="Role"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.role"
                  :class="{
                    err: errs.role.length,
                  }"
                />
                <p class="mt-1 text-red text-xs">{{ errs.role }}</p>
              </div>
              <div class="mb-4">
                <select
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  v-model="form.product"
                  :class="{
                    err: errs.product.length,
                  }"
                >
                  <option value="" disabled selected>
                    Product of interest*
                  </option>
                  <option
                    v-for="(product, index) in products"
                    :key="index"
                    :value="product"
                  >
                    {{ product }}
                  </option>
                </select>
                <p class="mt-1 text-red text-xs">{{ errs.product }}</p>
              </div>
              <div class="mb-8">
                <textarea
                  rows="6"
                  class="w-full outline-none bg-form px-5 py-3 rounded-[8px]"
                  placeholder="Request description*"
                  v-model="form.message"
                  :class="{
                    err: errs.message.length,
                  }"
                ></textarea>
                <p class="mt-1 text-red text-xs">{{ errs.message }}</p>
              </div>
              <!-- <p v-if="success" class="text-green mb-2">
              Request submitted successfully!
            </p> -->
              <button class="red-btn">Send message</button>
            </form>
          </div>
        </div>
        <div
          v-if="loading"
          class="loader absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[#01010188] z-50"
        >
          <span
            class="inline-flex items-center w-[30px] h-[30px] bg-red rounded-full rott"
          >
            <span class="bg-black w-[15px] h-[2px]"></span>
          </span>
        </div>
      </section>
      <section
        v-if="success"
        class="success fixed top-0 left-0 z-[9999] w-full h-screen bg-[#01010188] flex items-center justify-center"
      >
        <div
          class="relative min-w-[300px] max-w-[350px] p-4 bg-form rounded-[8px]"
        >
          <div class="flex items-center justify-end">
            <div
              @click="closeAll"
              class="relative cursor-pointer w-[16px] h-[16px]"
            >
              <span
                class="w-full absolute top-[8px] left-0 block h-[2px] bg-white rotate-45"
              ></span>
              <span
                class="w-full absolute top-[8px] left-0 block h-[2px] bg-white -rotate-45"
              ></span>
            </div>
          </div>
          <div class="checkMark flex items-center justify-center w-full mt-12">
            <span
              class="inline-flex items-center justify-center rounded-full bg-green w-[80px] h-[80px]"
            >
              <img src="@/assets/svg/done.svg" class="w-[50%]" alt="" />
            </span>
          </div>
          <div class="w-full mt-12">
            <p
              class="text-2xl font-semibold text-center font-aleo"
              style="letter-spacing: 0.02em"
            >
              Message Sent!
            </p>
            <p class="text-center text-xs mt-2">
              Your entry is acknowledged. <br />
              Thank you for choosing Qoray.
            </p>
          </div>
        </div>
      </section>
    </nav>
  </div>
</template>

<script>
import gsap from "gsap";
import MarqueeText from "vue-marquee-text-component";

// import brochure from "@/assets/pdf/QORE BROCHURE.pdf";
export default {
  components: { MarqueeText },
  data() {
    return {
      scr: window.innerWidth,
      loading: false,
      success: false,
      form: {
        name: "",
        email: "",
        phone: "",
        organization: "",
        role: "",
        product: "",
        message: "",
        timeStamp: new Date(),
      },
      errs: {
        name: "",
        email: "",
        phone: "",
        role: "",
        organization: "",
        product: "",
        message: "",
      },
      products: [
        "Charging station",
        "Financing",
        "Manufacturing, Assembling & Sales",
        "Switch",
        "Others",
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.scr = window.innerWidth;
      if (this.isMobile) {
        gsap.set(".mobileMenu", { x: "101%", opacity: 0 });
      }
    });
    if (this.isMobile) {
      gsap.set(".mobileMenu", { x: "101%", opacity: 0 });
    }
  },
  computed: {
    isMobile() {
      return this.scr < 786;
    },
  },
  methods: {
    downloadFile() {
      // fetch("/pdf/QORE BROCHURE.pdf", { method: "GET", responseType: "blob" })
      //   .then((resp) => {
      // console.log(resp);
      // const blob = new Blob([resp], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = "/pdf/QORE BROCHURE.pdf";
      link.download = "Qoray Brochure";
      link.click();
      // URL.revokeObjectURL(link.href);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    openNav() {
      gsap.to(".mobileMenu", {
        x: "0%",
        opacity: 1,
        duration: 0.5,
        ease: "easeOut",
      });
    },
    closeNav() {
      gsap.to(".mobileMenu", {
        x: "101%",
        opacity: 0,
        duration: 0.3,
        ease: "easeIn",
      });
    },
    closeAll() {
      this.closeCon();
      this.success = false;
    },
    closeCon() {
      gsap.to(".conForm", { x: "101%", duration: 0.3, ease: "easeIn" });
    },
    validateForm() {
      this.errs = {
        name: "",
        email: "",
        phone: "",
        role: "",
        organization: "",
        product: "",
        message: "",
      };
      var rege = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      this.form.email = this.form.email.trim();
      if (this.form.name.length < 1) {
        this.errs.name = "Name is required";
        return false;
      }
      if (this.form.name.length < 3) {
        this.errs.name = "Name is too short";
        return false;
      }
      if (this.form.email.length < 1) {
        this.errs.email = "Email is required";
        return false;
      }
      if (!this.form.email.match(rege)) {
        this.errs.email = "Invalid email";
        return false;
      }
      if (this.form.phone.length < 1) {
        this.errs.phone = "Phone number is required";
        return false;
      }
      if (this.form.phone.length < 11) {
        this.errs.phone = "Phone number is too short";
        return false;
      }
      if (this.form.organization.length < 1) {
        this.errs.organization = "Organization is required";
        return false;
      }
      if (this.form.organization.length < 3) {
        this.errs.organization = "Organization name is too short";
        return false;
      }
      if (this.form.product.length < 1) {
        this.errs.product = "Please select product";
        return false;
      } else {
        var valid = false;
        this.products.forEach((product) => {
          if (this.form.product === product) {
            valid = true;
          }
        });
        if (!valid) {
          this.errs.product = "Invalid product selected";
          return false;
        }
      }
      if (this.form.message.length < 1) {
        this.errs.message = "Please type in your message";
        return false;
      }
      if (this.form.message.length < 10) {
        this.errs.message = "Please type in a detailed message";
        return false;
      }
      return true;
    },
    submitForn() {
      // console.log(process.env.VUE_APP_API_URL);
      if (this.validateForm()) {
        // console.log("submitting");
        this.loading = true;
        fetch(process.env.VUE_APP_API_URL + "contact-form/data.json", {
          method: "POST",
          body: JSON.stringify(this.form),
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            this.sendMail();
          });
      }
    },

    sendMail() {
      this.loading = true;
      let data = {
        service_id: process.env.VUE_APP_SERVICE_ID,
        template_id: process.env.VUE_APP_TEMPLATE_ID,
        user_id: process.env.VUE_APP_PUBLIC_KEY,
        template_params: JSON.parse(JSON.stringify(this.form)),
      };
      // console.log(data);
      // return;

      fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        // .then((res) => res.json())
        .then((resp) => {
          // console.log(resp);
          this.success = true;
          this.loading = false;
          this.form = {
            name: "",
            email: "",
            phone: "",
            role: "",
            organization: "",
            product: "",
            message: "",
          };
        })
        .catch((err) => {
          this.loading = false;
          this.errs.message = "Your message was sent successfully, Thank you";
          // setTimeout(() => {
          //   this.closeAll();
          // }, 5000);
          // console.log(err);
        });
    },
  },
  // watch: {
  //   isMobile() {
  //     if (this.isMobile) {
  //       gsap.set(".mobileMenu", { x: "101%" });
  //     }
  //   },
  // },
};
</script>

<style lang="scss" >
.cform {
  input,
  textarea {
    border: 1px solid #303030;
    transition: all 0.3s ease-out;
    &:focus {
      border: 1px solid #dc2318;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
input.err {
  border: 2px solid #dc2318;
}
.rott {
  animation: rrr 1s linear infinite;
}
@keyframes rrr {
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style scoped>
.mar {
  /* background-color: #255e13; */
  @apply bg-red;
  padding: 6px 0;
}
.marquee-text-text {
  margin-left: 250px;
  font-size: 1.5rem;
  /* color: #fdd826; */
  font-weight: 500;
  @apply text-white;
}
</style>